import axios from 'axios';
import { API_BASE_URL } from './config'


// Function to handle login
export const login = async (username, password) => {
    return await axios.post(`${API_BASE_URL}/login`, {username, password });
};

// Function to update password
export const updatePassword = async (token, currentPassword, newPassword) => {
    return await axios.put(`${API_BASE_URL}/update-password`, {
        "username": "admin",
        "currentPassword": currentPassword,
        "newPassword": newPassword,
    }, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// apikey management

// Function to generate API key
export const generateApiKey = async (token, username, email, expiry, userRoles) => {
    return await axios.post(`${API_BASE_URL}/generate-key`, {
        "username": username,
        "email": email,
        "expiry": expiry,
        "user_role": userRoles,
    }, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Function to get list of API keys
export const getApiKeys = async (token) => {
    return await axios.get(`${API_BASE_URL}/api-keys`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Function to delete an API key
export const deleteApiKey = async (token, apiKeyId) => {
    console.log(apiKeyId)
    return await axios.delete(`${API_BASE_URL}/api-keys/${apiKeyId}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};


// model route config

export const saveRoutingConfig = async (token, configData) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/save-config`, 
        configData, 
        {
            headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

export const getRoutingConfigs = async (token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/get-configs`, 
        {
            headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

export const deleteRoutingConfig = async (token, payload) => {
  try {
    // Sending DELETE request with routing_key as a query parameter
    const response = await axios.delete(`${API_BASE_URL}/delete-configs`, {
      params: payload,  // routing_key is passed as a query parameter
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

// role assignment

// Assign roles
export const assignRole = async (token, username, roleList) => {
  return await axios.post(
      `${API_BASE_URL}/assign_role`,
      { username: username, role_list: roleList },
      { headers: { Authorization: `Bearer ${token}` } }
  );
};

// Unassign roles
export const unassignRole = async (token, username, roleList) => {
  return await axios.post(
      `${API_BASE_URL}/unassign_role`,
      { username: username, role_list: roleList },
      { headers: { Authorization: `Bearer ${token}` } }
  );
};


// Manage deployed Model

export const getDeployedModel = async (token) => {
  return await axios.get(`${API_BASE_URL}/deployed_model`,
    {
        headers: { Authorization: `Bearer ${token}` },
    }
  ).then(response => {
      if(response.data.success){
        return response.data.data;
      }else{
        alert("Got Some issue Please try again")
        return []
      }
    })
    .catch(error => {
      console.error('Error fetching models:', error);
      return []
    });
}


// manage pipeline

export const getAllRunningPipelines = async (adminId, token) => {
  const user = {
    user_id: adminId,
    user_name: 'admin',
    user_email: '',
    api_key: '',
    user_role: ['admin'],
  };
  return axios.post(`${API_BASE_URL}/get_all_running_pipeline`, 
    user,
    {
      headers: { Authorization: `Bearer ${token}` },
  });
};

export const killJob = async (taskId, reason, token) => {
  return axios.delete(`${API_BASE_URL}/kill_job`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      task_id: taskId,
      reason,
    },
  });
};


export const getAllPipelines = async (adminId, token) => {

  const user = {
    user_id: adminId,
    user_name: 'admin',
    user_email: '',
    api_key: '',
    user_role: ['admin'],
  };
  try{
    const response =  await axios.post(`${API_BASE_URL}/all_pipeline`, 
      user,
      {
        headers: { Authorization: `Bearer ${token}` },
    });

    return response

  }catch(error){
    console.error('Error deleting pipeline:', error);
    throw error;
  }
};

export const deletePipeline = async (pipelineId, token) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/delete_pipeline`, {
      params: { pipeline_id: pipelineId }, 
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};


export const getJobDetails = async (requestData, token) => {
  const response = await fetch(`${API_BASE_URL}/get_all_job_status`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(requestData),
  });
  return response.json();
};
