import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs, Typography, Button } from "@mui/material";
import AddEditRuleForm from "./AddEditRuleForm";
import ShowConfigurations from "./ShowConfigurations";
import ManageModelAccess from "./ManageModelAccess"
import {
  saveRoutingConfig,
  getRoutingConfigs,
  deleteRoutingConfig,
} from "../services/api";

const ModelRoutingConfig = () => {
  const [activeTab, setActiveTab] = useState(0); // 0: Add/Edit, 1: Show
  const [routingKey, setRoutingKey] = useState("");
  const [rules, setRules] = useState([]); // Rules for current configuration
  const [allConfigs, setAllConfigs] = useState([]); // All saved configurations

  useEffect(() => {
    fetchConfigs();
  }, []);

  const fetchConfigs = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await getRoutingConfigs(token);

      setAllConfigs(response.data.data || []);
    } catch (error) {
      console.error("Error fetching configurations:", error);
    }
  };

  const handleSaveConfiguration = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const payload = { routing_key: routingKey, rules };
      await saveRoutingConfig(token, payload);
      fetchConfigs(); // Refresh list after saving
      alert("Configuration saved successfully!");
      setRoutingKey(""); // Reset inputs
      setRules([]);
      setActiveTab(1); // Switch to Show Configurations tab
    } catch (error) {
      console.error("Error saving configuration:", error);
      alert("Failed to save configuration.");
    }
  };

  const handleEditConfig = (config) => {
    setRoutingKey(config.routing_key);
    setRules(config.rules);
    setActiveTab(0); 
  };

  const onDelete = async (routing_key) => {
    try {
      const token = localStorage.getItem("adminToken");
      const payload = { routing_key: routing_key };
      
      // Wait for delete request to finish
      const response = await deleteRoutingConfig(token, payload);
      
      // If successful, fetch updated configurations
      if (response.data.success) {
        fetchConfigs();  // Fetch new list of configurations after deletion
        alert("Configuration deleted successfully");
      } else {
        alert("Failed to delete configuration: " + response.data.message);
      }
    } catch (error) {
      console.error("Error deleting configuration:", error);
      alert("Error deleting configuration: " + error.message);
    }
  };  

  return (
    <Box sx={{ width: "100%" }}>
      {/* Tab Navigation */}
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        variant="fullWidth"
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="Add/Edit Rule" />
        <Tab label="Show Configurations" />
        <Tab label="Generate Model Access" />
      </Tabs>

      {/* Tab Content */}
      <Box sx={{ padding: 2 }}>
        {activeTab === 0 && (
          <AddEditRuleForm
            routingKey={routingKey}
            setRoutingKey={setRoutingKey}
            rules={rules}
            setRules={setRules}
            onSave={handleSaveConfiguration}
          />
        )}
        {activeTab === 1 && (
          <ShowConfigurations
            configs={allConfigs}
            onEdit={handleEditConfig}
            onDelete={onDelete} // Refresh after delete
          />
        )}
        {activeTab === 2 && (
          <ManageModelAccess
          />
        )}
      </Box>
    </Box>
  );
};

export default ModelRoutingConfig;
