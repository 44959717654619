import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../services/config";
import "./ManageModelAccess.css";

const ManageModelAccess = () => {
  const [accessConfigs, setAccessConfigs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ text: "", type: "" });
  const [visibleKeys, setVisibleKeys] = useState({});
  const [visibleSecrets, setVisibleSecrets] = useState({});

  const token = localStorage.getItem("adminToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const getAccessConfigs = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${API_BASE_URL}/get-access-configs`, { headers });
      setAccessConfigs(res.data.data);
    } catch (error) {
      console.error(error);
      setMessage({ text: "❌ Failed to fetch access configs.", type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const addAccessKey = async (routingKey) => {
    try {
      await axios.post(`${API_BASE_URL}/add-access-key`, { routing_key: routingKey }, { headers });
      setMessage({ text: `✅ Key added to '${routingKey}'`, type: "success" });
      getAccessConfigs();
    } catch (error) {
      console.error(error);
      setMessage({
        text: `❌ Failed to add key to '${routingKey}': ${error?.response?.data?.message}`,
        type: "error",
      });
    }
  };

  const deleteAccessKey = async (routingKey, accessKey) => {
    try {
      await axios.delete(`${API_BASE_URL}/delete-access-key`, {
        headers,
        params: { routing_key: routingKey, access_key: accessKey },
      });
      setMessage({ text: `✅ Access key removed from '${routingKey}'`, type: "success" });
      getAccessConfigs();
    } catch (error) {
      console.error(error);
      setMessage({
        text: `❌ Failed to delete key: ${error?.response?.data?.message}`,
        type: "error",
      });
    }
  };

  const toggleKeyVisibility = (routingKey, index) => {
    const key = `${routingKey}-${index}`;
    setVisibleKeys((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const toggleSecretVisibility = (routingKey) => {
    setVisibleSecrets((prev) => ({ ...prev, [routingKey]: !prev[routingKey] }));
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setMessage({ text: "📋 Copied to clipboard!", type: "success" });
  };

  useEffect(() => {
    getAccessConfigs();
  }, []);

  return (
    <div className="access-container">
      <h2 className="text-2xl font-bold mb-6">🔐 Manage Model Access Keys</h2>

      {loading ? (
        <p>Loading...</p>
      ) : accessConfigs.length === 0 ? (
        <p>No access configurations found.</p>
      ) : (
        <div className="access-grid">
          {accessConfigs.map((config) => (
            <div key={config.routing_key} className="access-card">
              <div className="access-header">
                <h3>{config.routing_key}</h3>
                <button onClick={() => addAccessKey(config.routing_key)}>➕ Add Key</button>
              </div>

              <div>
                <strong>Access Keys ({config.access_keys.length})</strong>
                <div className="table-wrapper">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Key</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {config.access_keys.map((key, index) => {
                        const keyId = `${config.routing_key}-${index}`;
                        return (
                          <tr key={keyId}>
                            <td>{index + 1}</td>
                            <td className="secret-text-k">
                              {visibleKeys[keyId] ? key : "••••••••••••••••••••••••••••••••••••"}
                            </td>
                            <td>
                              <div className="access-key-actions">
                                <button onClick={() => toggleKeyVisibility(config.routing_key, index)}>
                                  {visibleKeys[keyId] ? "Hide" : "View"}
                                </button>
                                <button onClick={() => copyToClipboard(key)}>Copy</button>
                                <button onClick={() => deleteAccessKey(config.routing_key, key)}>Remove</button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {message.text && (
        <div
          className={`message-box ${message.type === "success" ? "message-success" : "message-error"
            }`}
        >
          {message.text}
        </div>
      )}
    </div>
  );
};

export default ManageModelAccess;
